const state = {
    modal_loading: true
}

const mutations = {
    MODAL_LOADING: (state, modal_loading) => {
        state.modal_loading = modal_loading
    }
}

const actions = {
    showModalLoading({ commit }) {
        commit('MODAL_LOADING', true)
    },
    closeModalLoading({ commit }) {
        commit('MODAL_LOADING', false)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
