export default {
    install(Vue, options) {
        function log(type, text = '', endpoint = '') {
            var currentdate = new Date()
            var datetime =
                currentdate.getDate() +
                '/' +
                (currentdate.getMonth() + 1) +
                '/' +
                currentdate.getFullYear() +
                ' @ ' +
                currentdate.getHours() +
                ':' +
                currentdate.getMinutes() +
                ':' +
                currentdate.getSeconds()
        }

        Vue.prototype.$log = {
            error(text, endpoint) {
                log('error', text, endpoint)
            },
            success(text, endpoint) {
                log('success', text, endpoint)
            },
            log
        }
    }
}
