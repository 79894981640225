var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-navbar',{staticStyle:{"box-shadow":"0px 1px 4px 2px grey"},attrs:{"type":"dark","variant":"csb","fixed":"absolute","sticky":""}},[_vm._t("default"),_c('b-navbar-brand',{staticClass:"ml-3",attrs:{"href":"#"}},[_c('img',{staticStyle:{"width":"10rem"},attrs:{"src":require("../../assets/images/logo5.jpg"),"hidden":_vm.toggleTheme1}})]),_c('b-navbar-nav',{staticClass:"ml-auto"},[_c('b-nav-item-dropdown',{staticClass:"ml-2",attrs:{"right":"","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [(_vm.user_info.sso_profile_pic)?_c('div',{staticStyle:{"overflow":"hidden","color":"white","border-radius":"50%"},style:({
                            boxShadow: _vm.boxshadowProfile,
                            backgroundColor: _vm.backgroundProfile,
                            cursor: 'pointer'
                        }),attrs:{"id":"tooltipuser"},on:{"mouseover":function($event){(_vm.boxshadowProfile = _vm.shadowButton),
                                (_vm.backgroundProfile = _vm.backgroundButton)},"mouseout":function($event){(_vm.boxshadowProfile = 'none'), (_vm.backgroundProfile = 'transparent')},"click":function($event){_vm.showTooltipUser = false}}},[(_vm.user_info.sso_profile_pic)?_c('img',{staticStyle:{"width":"30px"},attrs:{"src":_vm.user_info.sso_profile_pic,"alt":""}}):_vm._e()]):_c('b-icon-person-circle',{staticStyle:{"color":"white","border-radius":"50%"},style:({
                            boxShadow: _vm.boxshadowProfile,
                            backgroundColor: _vm.backgroundProfile,
                            cursor: 'pointer'
                        }),attrs:{"id":"tooltipuser"},on:{"mouseover":function($event){(_vm.boxshadowProfile = _vm.shadowButton),
                                (_vm.backgroundProfile = _vm.backgroundButton)},"mouseout":function($event){(_vm.boxshadowProfile = 'none'), (_vm.backgroundProfile = 'transparent')},"click":function($event){_vm.showTooltipUser = false}}})]},proxy:true}])},[_c('b-card',{staticStyle:{"width":"300px","border":"0px"},attrs:{"bg-variant":"white"}},[_c('a',[_vm._v(_vm._s(_vm.fname)+" "),(_vm.mname)?_c('a',[_vm._v(_vm._s(_vm.mname)+". ")]):_vm._e(),_vm._v(_vm._s(_vm.lname))]),_c('br'),_c('a',{staticStyle:{"font-size":"15px","opacity":"70%"}},[_vm._v(_vm._s(_vm.username)+" ")]),_c('br'),_c('hr'),_c('a',[_vm._v("Roles: ")]),_c('br'),_vm._l((_vm.roles),function(num){return _c('a',{key:num.index,staticStyle:{"font-size":"15px","opacity":"70%"}},[_vm._v(_vm._s(num)+" "),_c('br')])}),_c('br'),_c('a',[_vm._v("Branch: ")]),_c('br'),_c('a',{staticStyle:{"font-size":"15px","opacity":"70%"}},[_vm._v(_vm._s(_vm.user_dept))]),_c('br'),_c('hr',{staticStyle:{"margin-bottom":"0px"}}),_c('b-row',{staticClass:"button-hover",attrs:{"variant":"outline-secondary"},on:{"mousedown":function($event){_vm.write_feedback = true
                            _vm.refresh_key++},"click":function($event){return _vm.openFeedback()}}},[_c('b-container',[_vm._v(" Send Feedback ")])],1),_c('hr',{staticStyle:{"margin-top":"0px","margin-bottom":"0px"}}),_c('b-row',{staticClass:"button-hover",attrs:{"variant":"outline-secondary"},on:{"mousedown":function($event){_vm.write_feedback = true
                            _vm.refresh_key++},"click":function($event){return _vm.logout()}}},[_c('b-container',[_vm._v(" Sign Out ")])],1)],2)],1)],1)],2),_c('manage-account'),(_vm.write_feedback == true)?_c('feedback-modal',{key:_vm.refresh_key,attrs:{"creator":_vm.fullname,"anonymous":_vm.anonymous}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }