import loginRequest from '@/utils/loginRequest'
import request from '@/utils/request'

export function login(data) {
    return loginRequest({
        url: 'auth/getTokenV3',
        method: 'post',
        data
    })
}

export function loginGoogle(data) {
    return loginRequest({
        url: 'auth/getTokenSSOv2',
        method: 'post',
        data
    })
}

// export function getRefreshTokens(data) {
//   return loginRequest({
//     url: 'auth/refresh',
//     method: 'post',
//     data
//   })
// }

export function refreshAccessToken(data) {
    return loginRequest({
        url: 'auth/refresh',
        method: 'post',
        data
    })
}

export function getInfo(user_id) {
    return request({
        url: 'users/' + user_id,
        method: 'get'
    })
}

export function changePassword(data, user_id) {
    return request({
        url: 'users/change/pw/' + user_id,
        method: 'put',
        data
    })
}
