<template>
    <!--<b-modal ref="new-user-modal"
         @ok="saveUser"
         :ok-title="'Save'"
         :cancel-disabled="modalSave"
         :ok-disabled="modalSave"
         id="NewUser"
         no-close-on-backdrop no-close-on-esc
         size="custom"
         title="New User">-->
    <!-- @ok="handleOk" -->
    <b-modal
        id="ManageAccount"
        no-close-on-backdrop
        no-close-on-esc
        size="custom"
        title="Manage User Account"
    >
        <b-container class="modal-outer-container">
            <b-container class="modal-inner-container">
                <b-container>
                    <a style="font-size: 1.1rem">Personal Details</a>
                </b-container>

                <hr class="modal-hr" />

                <b-container>
                    <b-row>
                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>First Name: </a>
                                </b-row>
                                <b-row>
                                    <b-input-group>
                                        <b-form-input
                                            v-model.lazy="nu_firstName"
                                            class="modal-input blackBorder"
                                            :state="nu_firstNameState"
                                            @input="nu_FirstNameValidate()"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Middle Name: </a>
                                </b-row>
                                <b-row>
                                    <b-input-group>
                                        <b-form-input
                                            v-model.lazy="nu_middleName"
                                            class="modal-input blackBorder"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-row>
                                <b-col sm="9">
                                    <a>Last Name: </a>
                                </b-col>
                                <b-col sm="3">
                                    <a>Suffix: </a>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col md="9" v-bind:style="{ paddingRight: '0px' }">
                                    <b-input-group>
                                        <b-form-input
                                            placeholder=""
                                            v-model.lazy="nu_lastName"
                                            class="modal-input blackBorder"
                                            :state="nu_lastNameState"
                                            @input="nu_LastNameValidate()"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-col>
                                <b-col md="3">
                                    <b-input-group>
                                        <b-form-input
                                            placeholder=""
                                            v-model.lazy="nu_suffix"
                                            class="modal-input blackBorder"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-col>
                            </b-row>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Company: </a>
                                </b-row>
                                <b-row>
                                    <b-form-select
                                        v-model.lazy="nu_company"
                                        class="inputCustom sel-style"
                                        v-bind:class="{ inputCustom: true }"
                                        @change="nu_CompanyValidate()"
                                        :state="nu_companyState"
                                    >
                                        <b-form-select-option
                                            v-for="item in nu_companyList"
                                            :key="item.id"
                                            :value="item.company"
                                            >{{ item.company }}</b-form-select-option
                                        >
                                    </b-form-select>
                                    <b-spinner
                                        variant="secondary"
                                        :class="{ spinnerAnimation: true }"
                                        label="Spinning"
                                        :hidden="nu_companyAnimation"
                                    ></b-spinner>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Department: </a>
                                </b-row>
                                <b-row md="12">
                                    <b-input-group>
                                        <b-form-input
                                            placeholder=""
                                            v-model.lazy="nu_department"
                                            class="modal-input blackBorder"
                                            :state="nu_departmentState"
                                            @input="nu_DepartmentValidate()"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Position: </a>
                                </b-row>
                                <b-row>
                                    <b-form-input
                                        placeholder=""
                                        v-model.lazy="nu_position"
                                        class="modal-input blackBorder"
                                        :state="nu_positionState"
                                        @input="nu_PositionValidate()"
                                    ></b-form-input>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Email Address: </a>
                                </b-row>
                                <b-row>
                                    <b-input-group>
                                        <b-form-input
                                            placeholder=""
                                            v-model.lazy="nu_email"
                                            class="modal-input blackBorder"
                                            @input="nu_EmailValidate()"
                                            :state="nu_emailState"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Mobile No: </a>
                                </b-row>
                                <b-row>
                                    <b-input-group>
                                        <b-form-input
                                            placeholder=""
                                            v-model.lazy="nu_mobile"
                                            class="modal-input blackBorder"
                                            @input="nu_MobileValidate()"
                                            @keypress="isNumber($event)"
                                            :state="nu_mobileState"
                                            :maxlength="'11'"
                                        ></b-form-input>
                                    </b-input-group>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Status: </a>
                                </b-row>
                                <b-row>
                                    <b-form-select
                                        v-model.lazy="nu_status"
                                        class="inputCustom sel-style"
                                        v-bind:class="{ inputCustom: true }"
                                        @change="nu_StatusValidate()"
                                        :state="nu_statusState"
                                    >
                                        <b-form-select-option :value="'active'"
                                            >Active</b-form-select-option
                                        >
                                        <b-form-select-option :value="'inactive'"
                                            >Inactive</b-form-select-option
                                        >
                                    </b-form-select>
                                </b-row>
                            </b-container>
                        </b-col>

                        <b-col md="4" class="modal-col-mb">
                            <b-container>
                                <b-row>
                                    <a>Single sign-on: </a>
                                    <b-col>
                                        <label class="switch" v-b-tooltip.hover>
                                            <input
                                                type="checkbox"
                                                v-model="nu_sso"
                                                value="true"
                                                unchecked-value="false"
                                            />
                                            <!--<span class="slider round"></span>-->
                                            <div class="slider round">
                                                <!--ADDED HTML -->
                                                <span class="on">ON</span
                                                ><span class="off">OFF</span>
                                                <!--END-->
                                            </div>
                                        </label>
                                    </b-col>
                                </b-row>
                            </b-container>
                        </b-col>
                    </b-row>
                </b-container>
            </b-container>
            <!------------------------------------->
            <br />
            <b-container class="modal-inner-container">
                <b-container>
                    <label style="margin-bottom: 0.5rem">
                        <a style="font-size: 1.1rem">Roles</a>
                    </label>
                </b-container>

                <hr class="modal-hr" />

                <b-container>
                    <b-row>
                        <b-col md="12">
                            <template>
                                <div>
                                    <b-table
                                        :items="items"
                                        :fields="fields"
                                        striped
                                        responsive="sm"
                                    >
                                        <template v-slot:cell(status)="row">
                                            <label class="switch" v-b-tooltip.hover>
                                                <input
                                                    type="checkbox"
                                                    v-model="row.value"
                                                    value="true"
                                                    unchecked-value="false"
                                                />
                                                <!--<span class="slider round"></span>-->
                                                <div class="slider round">
                                                    <!--ADDED HTML -->
                                                    <span class="on">ON</span
                                                    ><span class="off">OFF</span>
                                                    <!--END-->
                                                </div>
                                            </label>
                                        </template>
                                    </b-table>
                                </div>
                            </template>
                        </b-col>
                    </b-row>
                </b-container>
            </b-container>

            <br />

            <b-container fluid :class="{ nu_SaveAnimation: true }" :hidden="savingAnimation">
                <b-spinner variant="light" :class="{ nu_SaveSpinner: true }"></b-spinner>
            </b-container>
        </b-container>
    </b-modal>
</template>

<script>
import { getRoles, getDepartment, getCompany } from '@/api/settings'

export default {
    data: function () {
        return {
            fields: [
                { key: 'roleName', label: 'Role' },
                { key: 'description', label: 'Description' },
                { key: 'status', label: 'Action' }
            ],
            items: [
                /*{ role: 'Admin', description: 'description' },
                { role: 'RBH', description: 'description' },
                { role: 'BBH', description: 'description' },*/
            ],

            nu_firstName: '',
            nu_middleName: '',
            nu_lastName: '',
            nu_suffix: '',
            nu_company: '',
            nu_department: '',
            nu_position: '',
            nu_email: '',
            nu_mobile: '',
            nu_status: '',
            nu_sso: false,
            savingAnimation: true,
            modalSave: false,

            // LOV

            nu_companyList: [],

            //New Customer input state for validation
            nu_firstNameState: null,
            nu_lastNameState: null,
            nu_companyState: null,
            nu_departmentState: null,
            nu_positionState: null,
            nu_emailState: null,
            nu_mobileState: null,
            nu_statusState: null,

            // loading
            nu_companyAnimation: false
        }
    },

    methods: {
        fetchRoles() {
            getRoles().then((response) => {
                this.items = response.data
            })

            //   getCompany().then(response => {
            //     this.nu_companyList = response.data

            //     this.nu_companyAnimation = true
            //   })

            getDepartment().then((response) => {
                //this.items = response.data
            })
        },

        resetModal() {
            this.clearData()
        },

        showModal() {
            this.fetchRoles()
        },

        //INPUT VALIDATION
        isNumber: function (evt) {
            evt = evt ? evt : window.event
            var charCode = evt.which ? evt.which : evt.keyCode
            if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
                evt.preventDefault()
            } else {
                return true
            }
        },

        validEmail(email) {
            var re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return re.test(this[email]) ? true : false
        },

        nu_FirstNameValidate() {
            if (this.nu_firstName.length > 0) {
                this.nu_firstNameState = null
            } else {
                this.nu_firstNameState = false
            }
        },
        nu_LastNameValidate() {
            if (this.nu_lastName.length > 0) {
                this.nu_lastNameState = null
            } else {
                this.nu_lastNameState = false
            }
        },
        nu_CompanyValidate() {
            if (this.nu_company.length > 0) {
                this.nu_companyState = null
            } else {
                this.nu_companyState = false
            }
        },
        nu_DepartmentValidate() {
            if (this.nu_department.length > 0) {
                this.nu_departmentState = null
            } else {
                this.nu_departmentState = false
            }
        },
        nu_PositionValidate() {
            if (this.nu_position.length > 0) {
                this.nu_positionState = null
            } else {
                this.nu_positionState = false
            }
        },
        nu_EmailValidate() {
            if (this.validEmail('nu_email')) {
                this.nu_emailState = null
            } else {
                this.nu_emailState = false
            }
        },
        nu_MobileValidate() {
            if (this.nu_mobile.length > 0) {
                this.nu_mobileState = null
            } else {
                this.nu_mobileState = false
            }
        },
        nu_StatusValidate() {
            if (this.nu_status.length > 0) {
                this.nu_statusState = null
            } else {
                this.nu_statusState = false
            }
        },

        clearData() {
            this.nu_firstName = ''
            this.nu_middleName = ''
            this.nu_lastName = ''
            this.nu_suffix = ''
            this.nu_company = ''
            this.nu_department = ''
            this.nu_position = ''
            this.nu_email = ''
            this.nu_mobile = ''
            this.nu_status = ''
            this.nu_sso = false
            this.savingAnimation = true
            this.modalSave = false

            //New Customer input state for validation
            this.nu_firstNameState = null
            this.nu_lastNameState = null
            this.nu_companyState = null
            this.nu_departmentState = null
            this.nu_positionState = null
            this.nu_emailState = null
            this.nu_mobileState = null
            this.nu_statusState = null
        },

        handleOk(bvModalEvt) {
            // Prevent modal from closing
            bvModalEvt.preventDefault()
            // Trigger submit handler
            this.saveUser()
        },

        // save button
        saveUser() {
            //bvModalEvt.preventDefault()

            this.savingAnimation = false
            this.modalSave = true

            if (
                this.nu_firstName.length > 0 &&
                this.nu_lastName.length > 0 &&
                this.nu_company.length > 0 &&
                this.nu_department.length > 0 &&
                this.nu_position.length > 0 &&
                this.nu_email.length > 0 &&
                this.nu_mobile.length > 0 &&
                this.nu_status.length > 0
            ) {
                this.$nextTick(() => {
                    this.$bvModal.hide('NewUserModal')
                })
            } else {
                this.nu_FirstNameValidate()
                this.nu_LastNameValidate()
                this.nu_CompanyValidate()
                this.nu_DepartmentValidate()
                this.nu_PositionValidate()
                this.nu_EmailValidate()
                this.nu_MobileValidate()
                this.nu_StatusValidate()

                this.savingAnimation = true
                this.modalSave = false
            }
        }
    },
    computed: {},

    mounted: function () {}
}
</script>
