import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'

Vue.use(Router)

/* Layout */
import Layout from '@/layout'
import LayoutLogin from '@/layoutLogin'

export const constantRoutes = [
    {
        path: '/redirect',
        component: Layout,
        hidden: true,
        children: [
            {
                path: '/redirect/:path(.*)',
                component: () => import('@/views/redirect/index')
            }
        ]
    },
    // {
    //     path: '/login',
    //     component: () => import('@/views/login/index'),
    //     hidden: true
    // },
    {
        path: '/login',
        hidden: true,
        component: LayoutLogin,
        redirect: '/login/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/login/index'),
                name: 'Login',
                meta: { title: 'Login' }
            }
        ]
    },

    {
        path: '/change-password',
        hidden: true,
        component: LayoutLogin,
        redirect: '/change-password/index',
        children: [
            {
                path: 'index',
                component: () => import('@/views/change-password/index'),
                name: 'ChangePassword',
                meta: { title: 'Change Password' }
            }
        ]
    },
    {
        path: '/auth-redirect',
        component: () => import('@/views/login/auth-redirect'),
        hidden: true
    },
    {
        path: '/401',
        component: () => import('@/views/error-page/401'),
        hidden: true
    },
    {
        path: '/',
        //Route Guard
        beforeEnter: (to, from, next) => {
            const roles = store.getters.roles
            //next({ name: 'Address' })

            if (roles.includes('Controllership') || roles.includes('Developer ')) {
                next({ name: 'Credex' })
            } else {
                next({ path: 'Address' })
            }
            // if(roles.includes('MARIA') && roles.includes('CUSTOMER')) {

            // } else {
            //     next({ name: 'Talktomaria' })
            // }
        }
    },

    {
        path: '/forbidden_page',
        component: Layout,
        redirect: '/forbidden_page/index',
        children: [
            {
                path: 'index/:id',
                component: () => import('@/components/forbidden_page/index'),
                name: 'ForbiddenPage',
                meta: { title: 'ForbiddenPage' }
            }
        ]
    }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */
export const asyncRoutes = [
    {
        path: '/credex',
        component: Layout,
        redirect: '/credex/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/credex/CredexMaster'),
                name: 'Credex',
                meta: { title: 'Credex', roles: ['Developer ', 'Controllership'] }
            }
        ]
    },
    {
        path: '/address',
        component: Layout,
        redirect: '/address/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/settings/dropdown_tables/AddressMaster'),
                name: 'Address',
                meta: {
                    title: 'Address',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/branch',
        component: Layout,
        redirect: '/branch/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/settings/dropdown_tables/BranchMaster'),
                name: 'Branch',
                meta: {
                    title: 'Branch',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/company',
        component: Layout,
        redirect: '/company/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/company_master/CompanyMaster'),
                name: 'Company',
                meta: {
                    title: 'Company',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/scheme',
        component: Layout,
        redirect: '/scheme/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/scheme/SchemeMaster'),
                name: 'Scheme',
                meta: {
                    title: 'Scheme',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/dealer',
        component: Layout,
        redirect: '/dealer/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/settings/dropdown_tables/DealerMaster'),
                name: 'Dealer',
                meta: {
                    title: 'Scheme',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/dealerBranch',
        component: Layout,
        redirect: '/dealerBranch/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/settings/dropdown_tables/DealerBranchMaster'),
                name: 'DealerBranch',
                meta: {
                    title: 'Scheme',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/dealerPricelist',
        component: Layout,
        redirect: '/dealerPricelist/index',
        children: [
            {
                path: 'index',
                component: () =>
                    import('@/components/settings/dropdown_tables/DealerPricelistMaster'),
                name: 'DealerPricelist',
                meta: {
                    title: 'Scheme',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/lov',
        component: Layout,
        redirect: '/lov/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/settings/dropdown_tables/ListOfValues'),
                name: 'LOV',
                meta: {
                    title: 'LOV',
                    roles: [
                        'Developer ',
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/onlineBilling',
        component: Layout,
        redirect: '/onlineBilling/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/online_billing/OnlineBillingMaster'),
                name: 'LOV',
                meta: {
                    title: 'LOV',
                    roles: [
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/BCP',
        component: Layout,
        redirect: '/BCP/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/settings/dropdown_tables/BCPMaster'),
                name: 'BCP',
                meta: {
                    title: 'LOV',
                    roles: [
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/monthLags',
        component: Layout,
        redirect: '/monthLags/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/month_lag/MonthLagMaster'),
                name: 'MonthLags',
                meta: {
                    title: 'Month Lags',
                    roles: [
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/approverLimit',
        component: Layout,
        redirect: '/approverLimit/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/approver_limit/ApproverLimitMaster'),
                name: 'ApproverLimit',
                meta: {
                    title: 'Month Lags',
                    roles: [
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    {
        path: '/logs',
        component: Layout,
        redirect: '/logs/index',
        children: [
            {
                path: 'index',
                component: () => import('@/components/log/LogMaster'),
                name: 'Logs',
                meta: {
                    title: 'Logs',
                    roles: [
                        'ADMINISTRATOR',
                        'CUSTOMER',
                        'Super Administrator',
                        'Office Administrator'
                    ]
                }
            }
        ]
    },

    // 404 page must be placed at the end !!!
    {
        path: '*',
        component: Layout,
        redirect: '/error-page/404',
        children: [
            {
                path: '404',
                component: () => import('@/views/error-page/404'),
                name: '404',
                meta: { title: '404' }
            }
        ]
    }
]

const createRouter = () =>
    new Router({
        mode: 'history', // require service support
        base: '/',
        scrollBehavior: () => ({ y: 0 }),
        routes: constantRoutes
    })

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
    const newRouter = createRouter()
    router.matcher = newRouter.matcher // reset router
}

export default router
