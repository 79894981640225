var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-container',{staticClass:"app-wrapper-login",style:({
        backgroundImage:
            '-webkit-linear-gradient(160deg, #F5A847 ' + _vm.gradient.toString() + '%, #4f2684 18%)'
    }),attrs:{"fluid":"","id":"app"}},[_c('b-row',[_c('b-col',{attrs:{"lg":_vm.first}},[_c('div',{staticStyle:{"margin-top":"8%"}},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannerMargin == 'none'),expression:"bannerMargin == 'none'"}],staticStyle:{"border-style":"solid","border-color":"white","height":"11%","width":"11%","border-radius":"50%"},attrs:{"src":require("../assets/images/bee.png")}}),_c('img',{staticStyle:{"width":"53%"},style:({ float: _vm.bannerMargin }),attrs:{"src":require("../assets/images/logo5.jpg")},on:{"load":_vm.onImg2Load}}),_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannerMargin !== 'none'),expression:"bannerMargin !== 'none'"}],staticStyle:{"border-style":"solid","border-color":"white","height":"11%","width":"11%","border-radius":"50%"},style:({ float: _vm.bannerMargin }),attrs:{"src":require("../assets/images/bee.png")},on:{"load":_vm.onImg3Load}})])]),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLogo),expression:"showLogo"}],attrs:{"lg":_vm.second}},[_c('div',{staticStyle:{"display":"block","margin-right":"auto","margin-left":"auto"},style:({
                    marginTop: _vm.logoTopMargin.toString() + '%',
                    height: _vm.logoHeight + '%',
                    width: _vm.logoWidth + '%',
                    marginRight: _vm.logoRightMargin + '%'
                })},[_c('img',{staticStyle:{"border-style":"solid","border-color":"#4f2684","width":"100%","border-radius":"50%"},attrs:{"src":require("../assets/central/central sever.png")},on:{"load":_vm.onImg1Load}})])]),_c('b-col',{style:({ marginTop: _vm.top.toString() + '%' }),attrs:{"lg":_vm.third,"align-v":"center"}},[_c('app-main')],1)],1),_c('b-container',{staticClass:"login-animation",attrs:{"fluid":"","hidden":_vm.login_loading}},[_c('b-spinner',{staticClass:"login-spinner",attrs:{"variant":"light"}})],1),_c('vue-splash',{attrs:{"show":!_vm.showMain,"logo":_vm.logo,"color":"#F5A847","size":150,"fixed":true}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }